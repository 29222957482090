import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  Typography,
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { colors } from '../../theme'
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';


const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    }
  },
  card: {
    flex: '1',
    height: '25vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    borderRadius: '0px',
    transition: 'background-color 0.2s linear',
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
      minWidth: '20%',
      minHeight: '50vh',
    }
  },
  asset: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.red,
      '& .title': {
        color: colors.white
      },
      '& .icon': {
        color: colors.white
      },
      '& .description': {
        display: 'block',
        color: colors.white,
        padding: '80px',
        textAlign: 'center',
        lineHeight: 1.5
      }
    },
    '& .title': {
      color: colors.red
    },
    '& .icon': {
      color: colors.red
    },
    '& .description': {
      display: 'none'
    }
  },
  earn: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.pink,
      '& .title': {
        color: colors.white
      },
      '& .icon': {
        color: colors.white
      },
      '& .description': {
        display: 'block',
        color: colors.white,
        padding: '80px',
        textAlign: 'center',
        lineHeight: 1.5
      }
    },
    '& .title': {
      color: colors.pink
    },
    '& .icon': {
      color: colors.pink
    },
    '& .description': {
      display: 'none'
    }
  },
  zap: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.blue,
      '& .title': {
        color: colors.white
      },
      '& .icon': {
        color: colors.white
      },
      '& .description': {
        display: 'block',
        color: colors.white,
        padding: '80px',
        textAlign: 'center',
        lineHeight: 1.5
      }
    },
    '& .title': {
      color: colors.blue
    },
    '& .icon': {
      color: colors.blue
    },
    '& .description': {
      display: 'none'
    }
  },
  apr: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.lightBlack,
      '& .title': {
        color: colors.white
      },
      '& .icon': {
        color: colors.white
      },
      '& .description': {
        display: 'block',
        color: colors.white,
        padding: '80px',
        textAlign: 'center',
        lineHeight: 1.5
      }
    },
    '& .title': {
      color: colors.lightBlack
    },
    '& .icon': {
      color: colors.lightBlack
    },
    '& .description': {
      display: 'none'
    }
  },
  cover: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.compoundGreen,
      '& .title': {
        color: colors.white,
      },
      '& .icon': {
        color: colors.white
      },
      '& .description': {
        display: 'block',
        color: colors.white,
        padding: '80px',
        textAlign: 'center',
        lineHeight: 1.5
      }
    },
    '& .title': {
      color: colors.compoundGreen,
    },
    '& .icon': {
      color: colors.compoundGreen
    },
    '& .description': {
      display: 'none'
    }
  },
  title: {
    padding: '24px',
    paddingBottom: '0px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '24px'
    }
  },
  icon: {
    fontSize: '60px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '100px',
    }
  },
  link: {
    textDecoration: 'none'
  }
});

class Home extends Component {

  constructor(props) {
    super()

    this.state = {
    }
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={ classes.root }>
        <Card className={ `${classes.card} ${classes.earn}` } onClick={ () => {window.location.href="https://dex.trams.io"} }>
          <DashboardIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h1'} className={ `${classes.title} title` }>{ t("Home.Dashboard") }</Typography>
          <Typography variant={'h2'} className={ `${classes.description} description` }>{ "A snapshot at key growth metrics and transaction summary from TRAMS DEX  analytics" }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.zap}` } onClick={ () => {window.location.href="https://exchange.trams.io"} }>
          <SwapVerticalCircleIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h1'} className={ `${classes.title} title` }>{ t("Home.Swap") }</Typography>
          <Typography variant={'h2'} className={ `${classes.description} description` }>{ "Easily exchange ERC20 tokens on demand with constant supply and growing liquidity pools listed on TRAMS DEX" }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.apr}` } onClick={ () => { window.location.href="https://staking.trams.io/" } }>
          <TrendingUpIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h1'} className={ `${classes.title} title` }>{ t("Home.Farm") }</Typography>
          <Typography variant={'h2'} className={ `${classes.description} description` }>{ "Earn income with TRAMS DEX by simply providing liquidity, (+) add tokens to various pools of choice" }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.cover}` } onClick={ () => {window.location.href="http://staking.trams.io/" }}>
          <MonetizationOnIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h1'} className={ `${classes.title} title` }>{ t("Home.Yield") }</Typography>
          <Typography variant={'h2'} className={ `${classes.description} description` }>{ "Recieve additional TRAMS tokens bonus rewards by staking TRAMS Liquidity Provider (TLP) Tokens" }</Typography>
        </Card>

        {/* <Card className={ `${classes.card} ${classes.asset}` } onClick={ () => { window.location.href="https://assets.trams.io" }}>
          <DevicesOtherIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h1'} className={ `${classes.title} title` }>{ t("Home.Assets") }</Typography>
          <Typography variant={'h2'} className={ `${classes.description} description` }>{ "Get a quick glance at how your portfolio is growing while invested in yearn's products." }</Typography>
        </Card */}
      </div>
    )
  };

  nav = (screen) => {
    this.props.history.push(screen)
  }
}

export default withNamespaces()(withRouter(withStyles(styles)(Home)));
